import NavLink from "@liberetech/design-system/NavLink";
import classNames from "classnames";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import LayoutLanguageSelector from "../LayoutLanguageSelector/LayoutLanguageSelector";

import { useDevice } from "components/Context/PageContext";
import { LanguageAlternates } from "components/Seo/Seo";
import { useTranslation } from "lib/i18n/client";
import { Content } from "types";

import styles from "./LayoutSideMenu.module.css";

ReactModal.setAppElement("#__next");

const LayoutSideMenu: React.FC<LayoutSideMenuProps> = ({
  isOpen,
  content,
  languageAlternates,
  onRequestClose,
  isMyPlace,
}) => {
  const { t } = useTranslation("common");
  const { isNativeApp } = useDevice();
  const [isMounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setMounted(false);
    }
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      className={styles.sideMenu}
      overlayClassName={classNames(styles.overlay, {
        [styles.isOpen]: isMounted && isOpen,
        [styles.isMyPlace]: isMyPlace,
      })}
      bodyOpenClassName={styles.openBody}
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      onAfterOpen={() => setMounted(true)}
    >
      {isMyPlace && (
        <button
          aria-label={t("header.logout")}
          className={styles.logout}
          onClick={() => {
            window.dataLayer.push({
              event: "sign_out",
              event_category: "account",
              event_label: "sign_out_button",
            });
            document.location.href = "/logout";
          }}
        >
          <span>{t("header.logout")}</span>
          <svg viewBox="0 0 20 24">
            <path d="M5 12h13.5" />
            <path d="M13.25 17.25L18.5 12l-5.25-5.25" />
            <path d="M16.25 4.5V.75h-15v22.5h15V19.5" />
          </svg>
        </button>
      )}
      {content.footerMenu?.length > 0 && (
        <ul className={styles.menuItems}>
          {(content.footerMenu[0].children || []).map(
            ({ title, href, ...props }, i) => (
              <li
                key={i}
                className={styles.menuItem}
                onClick={() => {
                  onRequestClose && onRequestClose();
                }}
              >
                <NavLink href={href} {...props}>
                  {title}
                </NavLink>
              </li>
            ),
          )}
        </ul>
      )}
      {!isNativeApp && (
        <div className={styles.languageSelector}>
          <LayoutLanguageSelector
            className={styles.languageSelectorButton}
            languageAlternates={languageAlternates}
          />
        </div>
      )}
    </ReactModal>
  );
};

type LayoutSideMenuProps = {
  isOpen: boolean;
  isMyPlace: boolean;
  content: Content | Record<string, never>;
  languageAlternates: LanguageAlternates;
  onRequestClose?: () => void;
};

export default LayoutSideMenu;
